import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import Title from '@/components/Title';
import LabelWrapper from '@/components/LabelWrapper';
import { TextField } from '@/components/Lux';
import { Row, Column, Label, Button } from '@lux/components';

import * as actions from '@/actions/LoginActions';
import { useValidate, usePartialValidate } from '@/utils/hookUtils';
import { getVendorTarget } from '@/utils/helpers/login';
import { Container } from './styles';
import validationMsg from '@/utils/constants/validationMsg.json';

const validateList = ['email'];

const ForgotPassword = ({ userid, flow, isPage }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const isValid = useValidate(
    { email },
    validateList,
    validationMsg.forgotPassword
  );
  const { validation, setValidationList, onValidate } = usePartialValidate(
    { email },
    validationMsg.forgotPassword
  );

  const resetPassword = () => {
    dispatch(actions.forgotPassword.request(email, flow));
  };

  const cancelAction = () => {
    const redirectTo = flow === 'UDF' ? getVendorTarget(flow) : '/home';
    navigate(redirectTo);
  };

  const onKeyUp = (e) => {
    e.keyCode === 13 && resetPassword();
  };

  const onInputChange = (e) => {
    const value = e.target.value;
    setEmail(value);
  };

  useEffect(() => {
    userid && setEmail(userid);
  }, [userid]);

  useEffect(() => {
    setValidationList(['email']);
  }, []);

  return (
    <Container>
      <Row>
        <Title level={5}>Forgot Password </Title>
      </Row>
      <Row>
        <Label>
          <span>
            Need to reset your password? Please enter your email address and we
            will send you an email to reset your password.
          </span>
          <LabelWrapper
            md={12}
            lg={12}
            label=""
            breakPoint={() => {}}
            hintMessage={validation.email?.message}
          >
            <TextField
              id="forgotPswUserID"
              name="forgotPswEmail"
              type="forgotPswEmail"
              placeholder="e.g. john.doe@singtel.com"
              value={email}
              onChange={onInputChange}
              onKeyUp={onKeyUp}
              onBlur={onValidate}
              error={validation.email?.isInValid}
              autoComplete={'off'}
            />
          </LabelWrapper>
        </Label>
      </Row>
      <Row end={'sm'}>
        {flow === 'UDF' && isPage ? (
          <Column>
            <Button onClick={cancelAction}>Cancel</Button>
          </Column>
        ) : null}
        <Button disabled={!isValid} onClick={resetPassword}>
          {flow === 'UDF' ? 'Save' : 'Send'}
        </Button>
      </Row>
    </Container>
  );
};

ForgotPassword.propTypes = {
  userid: PropTypes.string,
  flow: PropTypes.string,
  isPage: PropTypes.bool
};

export default ForgotPassword;
