import styled from 'styled-components';
import Theme from '@/theme';

const hintColorMap = {
  danger: Theme.colours.rederror,
  warning: Theme.colours.yellow_d2,
  info: Theme.colours.grey_700,
  success: Theme.colours.green_60
};

export default {
  Container: styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 5px 0;
    height: 100%;
    flex-wrap: wrap;
    ${(p) => p.breakPoint`
      flex-wrap:nowrap;
    `};
  `,
  Label: styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-right: 20px;
    font-family: ${Theme.fonts.heading300};
    ${(p) => p.breakPoint`
      width: ${p.labelWidth};
    `};
  `,
  LabelP: styled.p`
    font-weight: 600;
    margin-bottom: 10px;
    ${(p) => p.breakPoint`
      margin-bottom:0;
    `};
  `,
  LabelSymbol: styled.p`
    display: none;
    ${(p) => p.breakPoint`
      display:block;
    `};
  `,
  LabelChildren: styled.div`
    width: 100%;
    display: ${(p) => (p.children && p.children.length > 1 ? 'flex' : 'block')};
    flex-wrap: wrap;
    ${(p) => p.breakPoint`
      width: calc(100% - ${p.labelWidth})
    `};
  `,
  Hint: styled.div`
    font-size: 0.875rem;
    color: ${(p) =>
      hintColorMap[p.validation]
        ? hintColorMap[p.validation]
        : Theme.colours.grey_700};
    ${(p) => p.breakPoint`
      margin-left: 20px;
      padding-left: ${p.labelWidth};
    `};
  `
};
