import React from 'react';
import { Column } from '@lux/components';
import PropTypes from 'prop-types';
import SC from './style';
import Theme from '@/theme';

export default function LabelWrapper(props) {
  const {
    label,
    hintMessage,
    validation = 'danger',
    labelWidth = '30%',
    halfRow = false,
    sm = 12,
    md,
    lg,
    breakPoint = Theme.media.md
  } = props;
  return (
    <Column
      sm={sm}
      md={md ? md : halfRow ? 6 : 10}
      lg={lg ? lg : halfRow ? 6 : 8}
    >
      <div>
        <SC.Container breakPoint={breakPoint}>
          <SC.Label labelWidth={labelWidth} breakPoint={breakPoint}>
            <SC.LabelP breakPoint={breakPoint}>{label}</SC.LabelP>
            <SC.LabelSymbol breakPoint={breakPoint}>
              {label ? ':' : null}
            </SC.LabelSymbol>
          </SC.Label>
          <SC.LabelChildren labelWidth={labelWidth} breakPoint={breakPoint}>
            {props.children}
          </SC.LabelChildren>
        </SC.Container>
        {hintMessage && (
          <Column>
            <SC.Hint
              labelWidth={labelWidth}
              validation={validation}
              breakPoint={breakPoint}
            >
              {hintMessage}
            </SC.Hint>
          </Column>
        )}
      </div>
    </Column>
  );
}

LabelWrapper.propTypes = {
  /* label display on the left side */
  label: PropTypes.string,
  /* width of label, 40% by default */
  labelWidth: PropTypes.string,
  /* width of element, false by default */
  halfRow: PropTypes.bool,
  /* grid props, pass to column component */
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  /* child node, can be textField, dropdown,etc... */
  children: PropTypes.node,
  /* hint messages */
  hintMessage: PropTypes.string,
  /* hint message color */
  validation: PropTypes.oneOf(['danger', 'info', 'warning', 'success']),
  /* breakpoint of its two forms */
  breakPoint: PropTypes.func
};
